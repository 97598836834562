<template>
  <app-module-view>
    <template slot="body">
      <div class="single-column">
        <div id="main-wrapper">
          <div class="page-wrapper">
            <div class="container-fluid">
              <section>
                <p class="card-subtitle text-muted text-right m-t-10">
                  {{ $t('video.problemsContact') }}:
                  <a :href="`mailto:${supportEmail}`">
                    {{ supportEmail }}
                  </a>
                </p>
                <h3>{{ $t('video.help.sections.intro') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="13961" :title="`${$t('video.help.v_13961.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13961.item1') }}</li>
                            <li>{{ $t('video.help.v_13961.item2') }}</li>
                            <li>{{ $t('video.help.v_13961.item3') }}</li>
                            <li>{{ $t('video.help.v_13961.item4') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13960" :title="`${$t('video.help.v_13960.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13960.item1') }}</li>
                            <li>{{ $t('video.help.v_13960.item2') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="26427" :title="`${$t('video.help.v_26427.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_26427.item1') }}</li>
                            <li>{{ $t('video.help.v_26427.item2') }}</li>
                            <li>{{ $t('video.help.v_26427.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>

                <h3>{{ $t('video.help.sections.filter') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="13948" :title="`${$t('video.help.v_13948.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13948.item1') }}</li>
                            <li>{{ $t('video.help.v_13948.item2') }}</li>
                            <li>{{ $t('video.help.v_13948.item3') }}</li>
                            <li>{{ $t('video.help.v_13948.item4') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13962" :title="`${$t('video.help.v_13962.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13962.item1') }}</li>
                            <li>{{ $t('video.help.v_13962.item2') }}</li>
                            <li>
                              <a
                                href="https://chrome.google.com/webstore/detail/nmh-eagle-extension/jchfnobgnpipofkiamejghlkpndfenkh"
                                target="_blank"
                                class="link"
                              >
                                {{ $t('video.help.v_13962.item3') }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="14926" :title="`${$t('video.help.v_14926.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_14926.item1') }}</li>
                            <li>{{ $t('video.help.v_14926.item2') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="24702" :title="`${$t('video.help.v_24702.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_24702.item1') }}</li>
                            <li>{{ $t('video.help.v_24702.item2') }}</li>
                            <li>{{ $t('video.help.v_24702.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>
                <h3>{{ $t('video.help.sections.articleCreationAndEditing') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="52695" :title="`${$t('video.help.v_52695.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_52695.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13943" :title="`${$t('video.help.v_13943.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13943.item1') }}</li>
                            <li>{{ $t('video.help.v_13943.item2') }}</li>
                            <li>{{ $t('video.help.v_13943.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13942" :title="`${$t('video.help.v_13942.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13942.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                  <div class="row m-t-20">
                    <div class="col-lg-4">
                      <app-help-video id="17483" :title="`${$t('video.help.v_17483.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_17483.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13945" :title="`${$t('video.help.v_13945.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13945.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13944" :title="`${$t('video.help.v_13944.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13944.item1') }}</li>
                            <li>{{ $t('video.help.v_13944.item2') }}</li>
                            <li>{{ $t('video.help.v_13944.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                  <div class="row m-t-20">
                    <div class="col-lg-4">
                      <app-help-video id="13940" :title="`${$t('video.help.v_13940.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13940.item1') }}</li>
                            <li>{{ $t('video.help.v_13940.item2') }}</li>
                            <li>{{ $t('video.help.v_13940.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13946" :title="`${$t('video.help.v_13946.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13946.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="28655" :title="`${$t('video.help.v_28655.title')}`"></app-help-video>
                    </div>
                  </div>
                  <div class="row m-t-20">
                    <div class="col-lg-4">
                      <app-help-video id="13941" :title="`${$t('video.help.v_13941.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13941.item1') }}</li>
                            <li>{{ $t('video.help.v_13941.item2') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                  </div>
                </div>
                </div>
                <h3>{{ $t('video.help.sections.workWithPhotos') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="13958" :title="`${$t('video.help.v_13958.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13958.item1') }}</li>
                            <li>{{ $t('video.help.v_13958.item2') }}</li>
                            <li>{{ $t('video.help.v_13958.item3') }}</li>
                            <li>{{ $t('video.help.v_13958.item4') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13955" :title="`${$t('video.help.v_13955.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13955.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13956" :title="`${$t('video.help.v_13956.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13955.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                  <div class="row m-t-20">
                    <div class="col-lg-4">
                      <app-help-video id="48883" :title="`${$t('video.help.v_48883.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_48883.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13957" :title="`${$t('video.help.v_13957.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13957.item1') }}</li>
                            <li>{{ $t('video.help.v_13957.item2') }}</li>
                            <li>{{ $t('video.help.v_13957.item3') }}</li>
                            <li>
                              <a
                                href="https://www.pluska.sk/files/pdf/dam-watermark.pdf"
                                target="_blank" class="link"
                              >
                                {{ $t('video.help.v_13957.item4') }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="15946" :title="`${$t('video.help.v_15946.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_15946.item1') }}</li>
                            <li>{{ $t('video.help.v_15946.item2') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                  <div class="row m-t-20">
                    <div class="col-lg-4">
                      <app-help-video id="18941" :title="`${$t('video.help.v_18941.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_18941.item1') }}</li>
                            <li>{{ $t('video.help.v_18941.item2') }}</li>
                            <li>{{ $t('video.help.v_18941.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="18956" :title="`${$t('video.help.v_18956.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_18956.item1') }}</li>
                            <li>{{ $t('video.help.v_18956.item2') }}</li>
                            <li>{{ $t('video.help.v_18956.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>

                <h3>{{ $t('video.help.sections.workWithVideos') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="18172" :title="`${$t('video.help.v_18172.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_18172.item1') }}</li>
                            <li>{{ $t('video.help.v_18172.item2') }}</li>
                            <li>{{ $t('video.help.v_18172.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="18796" :title="`${$t('video.help.v_18796.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_18796.item1') }}</li>
                            <li>{{ $t('video.help.v_18796.item2') }}</li>
                            <li>{{ $t('video.help.v_18796.item3') }}</li>
                            <li>{{ $t('video.help.v_18796.item4') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="18173" :title="`${$t('video.help.v_18173.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_18173.item1') }}</li>
                            <li>{{ $t('video.help.v_18173.item2') }}</li>
                            <li>{{ $t('video.help.v_18173.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>

                <h3>{{ $t('video.help.sections.modules') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="13949" :title="`${$t('video.help.v_13949.title')}`">
                        <div slot="description">
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13953" :title="`${$t('video.help.v_13953.title')}`">
                        <div slot="description">
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="61595" :title="`${$t('video.help.v_61595.title')}`">
                        <div slot="description">
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                  <div class="row m-t-20">
                    <div class="col-lg-4">
                      <app-help-video id="13950" :title="`${$t('video.help.v_13950.title')}`">
                        <div slot="description">
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13951" :title="`${$t('video.help.v_13951.title')}`">
                        <div slot="description">
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="13954" :title="`${$t('video.help.v_13954.title')}`">
                        <div slot="description">
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                  <div class="row m-t-20">
                    <div class="col-lg-4">
                      <app-help-video id="13952" :title="`${$t('video.help.v_13952.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>
                              <a
                                href="https://www.pluska.sk/files/pdf/eagle_kvizy.pdf"
                                target="_blank" class="link"
                              >
                                {{ $t('video.help.v_13952.item1') }}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="14925" :title="`${$t('video.help.v_14925.title')}`">
                        <div slot="description">
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="17482" :title="`${$t('video.help.v_17482.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_17482.item1') }}</li>
                            <li>{{ $t('video.help.v_17482.item2') }}</li>
                            <li>{{ $t('video.help.v_17482.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                  <div class="row m-t-20">
                    <div class="col-lg-4">
                      <app-help-video id="17484" :title="`${$t('video.help.v_17484.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_17484.item1') }}</li>
                            <li>{{ $t('video.help.v_17484.item2') }}</li>
                            <li>{{ $t('video.help.v_17484.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="28656" :title="`${$t('video.help.v_28656.title')}`"></app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="50823" :title="`${$t('video.help.v_50823.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_50823.item1') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>

                <h3>{{ $t('video.help.sections.print') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="17538" :title="`${$t('video.help.v_17538.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_17538.item1') }}</li>
                            <li>{{ $t('video.help.v_17538.item2') }}</li>
                            <li>{{ $t('video.help.v_17538.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="17537" :title="`${$t('video.help.v_17537.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_17537.item1') }}</li>
                            <li>{{ $t('video.help.v_17537.item2') }}</li>
                            <li>{{ $t('video.help.v_17537.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>

                <h3>{{ $t('video.help.sections.boxManagement') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="13947" :title="`${$t('video.help.v_13947.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_13947.item1') }}</li>
                            <li>{{ $t('video.help.v_13947.item2') }}</li>
                            <li>{{ $t('video.help.v_13947.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="26426" :title="`${$t('video.help.v_26426.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_26426.item1') }}</li>
                            <li>{{ $t('video.help.v_26426.item2') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="28651" :title="`${$t('video.help.v_28651.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li><a
                              href="https://damadmin.nmhmedia.sk/pdf/53be5e9c-eb76-4951-9828-370ca7fe92bc_hp-ponuka-clankov.pdf"
                              target="_blank">{{ $t('video.help.v_28651.item1') }}</a></li>
                            <li>{{ $t('video.help.v_28651.item2') }}</li>
                            <li>{{ $t('video.help.v_28651.item3') }}</li>
                            <li>{{ $t('video.help.v_28651.item4') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>

                <h3>{{ $t('video.help.sections.sponsoredArticles') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="26425" :title="`${$t('video.help.v_26425.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_26425.item1') }}</li>
                            <li>{{ $t('video.help.v_26425.item2') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>

                <h3>{{ $t('video.help.sections.statisticsAndPerformance') }}</h3>
                <div class="card card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <app-help-video id="36247" :title="`${$t('video.help.v_36247.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_36247.item1') }}</li>
                            <li>{{ $t('video.help.v_36247.item2') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="36206" :title="`${$t('video.help.v_36206.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_36206.item1') }}</li>
                            <li>{{ $t('video.help.v_36206.item2') }}</li>
                            <li>{{ $t('video.help.v_36206.item3') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                    <div class="col-lg-4">
                      <app-help-video id="42377" :title="`${$t('video.help.v_42377.title')}`">
                        <div slot="description">
                          <ul class="p-t-30">
                            <li>{{ $t('video.help.v_42377.item1') }}</li>
                            <li>{{ $t('video.help.v_42377.item2') }}</li>
                          </ul>
                        </div>
                      </app-help-video>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import { mapGetters } from 'vuex'
import ModuleView from '../../components/ModuleView'
import HelpVideo from '../../components/video/VideoHelp'

export default {
  name: 'HelpVideoListView',
  components: {
    appModuleView: ModuleView,
    appHelpVideo: HelpVideo
  },
  computed: {
    ...mapGetters(['vlm']),
    supportEmail () {
      return this.vlm ? 'support.vlm@newsandmedia.sk' : 'supportcms@newsandmedia.sk'
    }
  }
}
</script>

<template>
  <section>
    <h4>{{ title }}</h4>
    <div class="aspect-ratio">
      <iframe
        width="100%"
        height="100%"
        :src=url
        frameborder="0"
        allowfullscreen
      >
      </iframe>
    </div>
    <slot name="description"></slot>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import Config from '@/config'

const nmhVideoIdToVlmGoogleDriveUrlMap = {
  13961: '1Y-J_GqD462iqHUkH33-cU31ItXNzngpo', // title: 'Prihlasovacia obrazovka'
  13960: '1PaNIOD5aqcGAnmwL8orB_mx2ZHc-fBjb', // title: 'Stavy článku'
  26427: '1KLcbqEteChyDy3-AJIXw6cHXed-TYqYu', // title: 'Realtime štatistiky'
  13948: '10H-0gf8VhNb3R75D_PI83zdoOoVC_Rep', // title: 'Zoznam článkov'
  13962: '1fAo9zHXAkh6AzoXx3SxMRzu7zJCQ-2Fv', // title: 'Vyhľadávanie článku a Chrome plugin'
  14926: '1J9BXZuJpn8x45fF6gBnIJNXaHhTppD_e', // title: 'TASR agentúrne články'
  24702: '1TF2L2r0bOYoBHybvE3lkvj2sO0da6-Xm', // title: 'Ponuka obsahu',
  52695: '1HSR5Y4JbIcNbFRMkJY2iBXU24WsCs_Mq', // title: 'Nový Article editor'
  13943: '1oU3oxGuxuTfg-tySooiEilMFJ4KOASVT', // title: 'Hlavný obsah'
  13942: '1koQtjzISZFstLQx2BSPao4eD20nyI--B', // title: 'Hlavné video'
  17483: '1pUKIo9cv5TdIIVxSpg628JmhpswTwk0v', // title: 'Autorský podiel'
  13945: '1Jpn_TFuoordU-eB0d0o1NdTlJ73Xe-sZ', // title: 'Súvisiace články pod textom'
  13944: '1Li9snFPY1B3ZYQkb6H4y9nTFDZtFrFbG', // title: 'Nastavenia'
  13940: '1oM8jW3zH6TBhNHc2aeKM7efiY4y3Vmhb', // title: 'Ďalšie typy článkov'
  13946: '1QpaBWb-po6-g9ct1LLRb9KrbG7pqQPEo', // title: 'Fotostory'
  28655: '14UPODg72r5saWo2Kpa05-m_XN08YxrK2', //  title: 'Ochorenie'
  13941: '1mTd_tCVyc32yqVcHuB5HPD95zbjHkWqn', // title: 'Telo článku'
  13958: '1vqS07KyfvlbexBT7bnPQZBU2i956vTE4', // title: 'Hlavná fotografia'
  13955: '1x3VwzUbMi353DEm-ZowMalErqE6wlHYd', // title: 'Fotka v tele článku'
  13956: '10XVkZvyWrKSGnZWtF3s1MGkZz3djej2Y', // title: 'Galéria', item1: 'Pridávanie fotiek do galérie'
  48883: '1uRvNrGSMpIeWyFm_yhlWX7L68A0oiiEe', // title: 'Galéria', item1: 'Vkladanie obrázkov z verejných Instagram a Facebook postov do galérií článkov'
  13957: '1Iv5vP979qAE4CowT7kWdB1Fr_iP8VbP1', // title: 'DAM (banka fotiek)'
  15946: '1IMz4g1_C8LZVW31vXsqkjhQwC3_O_-PU', // title: 'DAM - pokročilé vyhľadávanie fotiek'
  18941: '1vpBAkc1J6Vd_f2X26ygs0PbI93uP_dvm', // title: 'TASR fotky'
  18956: '1ddbPpe8MWamIdVSf4Jw4B9_y132QPzlr', // title: 'Hromadné operácie'
  18172: '1wpZ0vbvX-IQKQ3-8F54sPGoVnMJT2BHs', // title: 'Zoznam videí - vyhľadávanie'
  18796: '1KSBN8Jclqot5tmPN-ZpiHg5zmG2Abtp7', // title: 'Nahrávanie nových videí'
  18173: '1wFJrmDlqssCthGlo5psog318ebOtwra9', // title: 'Video management'
  13949: '1RPrlH0eJauVTmKmxDHDRbLqennihDNaA', // title: 'Infoboxy'
  13953: '1W7bnlfgfAIFnQ-sAL1FxlUdlFepGJWhJ', // title: 'Vtipy'
  61595: '1mB3Xna_vOV0sDvZh59EYVNZzZz70ebqk', // title: 'Myšlienka týždňa'
  13950: '1Q07vrJw8z1vBtdHuUXm1qg8nyzNovQXy', // title: 'Ankety'
  13951: '1DQO_lGJdwQYwgHiMzw5cSYhCwf81dFLc', // title: 'Anketa na HP'
  13954: '1ioE_p2cuuYb7E7Ex5wRlwBGnsEDu8mXS', // title: 'Páči / Nepáči'
  13952: '15masrDbC7rZzZOEHnDyTODxtxE8XQ9Yg', // title: 'Kvízy'
  14925: '1u_iIbvnHAH203ThMh-12QcrVhnl8R-P0', // title: 'Športové tabuľky'
  17482: '1QJqLKCwlR5yWDoqXmr2uUaBrzWmMI3G-', // title: 'Témy'
  17484: '12DjwdPtizZh0lJlVQuSTdvSObAbZzBKv', // title: 'Vozidlá'
  28656: '16tX0jYKmp0S62S0RrL7oClwtlLWDcN8B', // title: 'Recepty'
  50823: '1JUc8RoF1ZKmbNcWgfuLwgoQkT5EtR8GE', // title: 'Galérie', item1: 'Vytváranie galérií v moduloch a ich vkladanie do článkov (aj pre opätovné použitie vo viacerých článkoch)'
  17538: '1bFN3H79WtTaYGTt-znRBhGSfnc8OZ1KK', // title: 'Printové publikácie'
  17537: '1aUvxTTd55oYsVZR2jVlua-eHh4CxAhvM', // title: 'Printové články'
  13947: '1_-7EZgw_OkgK_k5uBeZs7kPlq-88rLQg', // title: 'HP management'
  26426: '11-BhjjMyqXBIsQoq2MwX0G7VAElKEJBg', // title: 'HP management - Topbox CTR'
  28651: '1SLA70PfuwzTb47yO8FtLFQ82ftlCroQs', // title: 'HP ponuka článkov'
  26425: '1VkukIXpmixwlWQr8jxziU22VxQG9DJwf', // title: 'Manažment a vývoj kampane'
  36247: '1wT8lGhUe5oxjF5b_RgClemt7EI_0flTw', // title: 'NMH KPI'
  36206: '11GUbz_vIBKbwJu4kH1ZjQZ1kWZfy9_if', // title: 'Štatistiky produktu'
  42377: '1vDh28-ZOwqVf-6-ha0DSIIs5Ukkl4Id9' // title: 'KPI'
}

export default {
  name: 'HelpVideo',
  props: {
    id: {
      type: String
    },
    title: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    url () {
      if (this.vlm) {
        return `https://drive.google.com/file/d/${nmhVideoIdToVlmGoogleDriveUrlMap[this.id]}/preview`
      }
      // for NMH, keep the old eagle video player, so it can be easily modified in Admin
      return `${Config.eagleVideoPlayer.baseUrl()}${this.id}`
    }
  }
}
</script>

<style lang="scss">
div.aspect-ratio {
  padding-bottom: 56.25%;
  position: relative;
}

div.aspect-ratio iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
